<template>
  <main
    id="site-wrapper"
  >
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{name: 'admin'}">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{name: 'operational-dashboard'}">
              Operational Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ componentTitle | titleFormat }}
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'operational-dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>
              {{ componentTitle | titleFormat }}
            </h1>
          </div>
        </div>

        <SelectDates
          v-if="areDatesLoaded"
          :initial-range="range"
          :updating="isUpdating"
          @get-data="getData"
        />

        <div class="charts-wrapper">
          <div
            id="popular-protocols"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>Most Popular Protocols</h3>
              </div>
            </div>

            <div
              v-if="isLoading"
              class="isLoading"
            >
              loading data...
            </div>

            <div
              v-if="!isLoading"
              class="section-panel-body"
            >
              <div
                v-if="chartData.protocols && chartData.protocols.length > 0"
                class="chart-container"
              >
                <pie-chart
                  chart-id="protocols"
                  :start-date="dateStart"
                  :end-date="dateEnd"
                  :custom-labels="getLabels(chartData.protocols)"
                  :custom-b-g-colors="generateColourArray(chartData.protocols.length)"
                  :custom-data="getPropValues(chartData.protocols, 'percentage')"
                  :custom-count="getPropValues(chartData.protocols, 'participants')"
                  :styles="pieChartStyles"
                />
              </div>
              <div
                v-else
                class="no-data"
              >
                There isn't any protocol data available yet
              </div>
            </div>
          </div>

          <div
            id="popular-protocols"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>Most Popular Endpoints</h3>
              </div>
            </div>

            <div
              v-if="isLoading"
              class="isLoading"
            >
              loading data...
            </div>

            <div
              v-if="!isLoading"
              class="section-panel-body"
            >
              <div
                v-if="chartData.endpoints && chartData.endpoints.length > 0"
                class="chart-container"
              >
                <pie-chart
                  chart-id="endpoints"
                  :start-date="dateStart"
                  :end-date="dateEnd"
                  :custom-labels="getLabels(chartData.endpoints)"
                  :custom-b-g-colors="generateColourArray(chartData.endpoints.length)"
                  :custom-data="getPropValues(chartData.endpoints, 'percentage')"
                  :custom-count="getPropValues(chartData.endpoints, 'participants')"
                  :styles="pieChartStyles"
                />
              </div>
              <div
                v-else
                class="no-data"
              >
                There isn't any endpoint data available yet
              </div>
            </div>
          </div>

          <div
            id="popular-protocols"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>Most Popular Platforms</h3>
              </div>
            </div>

            <div
              v-if="isLoading"
              class="isLoading"
            >
              loading data...
            </div>

            <div
              v-if="!isLoading"
              class="section-panel-body"
            >
              <div
                v-if="chartData.platforms && chartData.platforms.length > 0"
                class="chart-container"
              >
                <pie-chart
                  chart-id="platforms"
                  :start-date="dateStart"
                  :end-date="dateEnd"
                  :custom-labels="getLabels(chartData.platforms)"
                  :custom-b-g-colors="generateColourArray(chartData.platforms.length)"
                  :custom-data="getPropValues(chartData.platforms, 'percentage')"
                  :custom-count="getPropValues(chartData.platforms, 'participants')"
                  :styles="pieChartStyles"
                />
              </div>
              <div
                v-else
                class="no-data"
              >
                There isn't any platform data available yet
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <div
            id="endpoints-table"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>Endpoints</h3>
              </div>
              <div
                v-if="range.start"
                class="section-panel-header-action date-range"
              >
                {{ dateStart | dateFormat }} - {{ dateEnd | dateFormat }}
              </div>
            </div>
            <div
              v-if="isLoadingData"
              class="isLoading"
            >
              loading data...
            </div>

            <div
              v-if="!isLoadingData"
              class="section-panel-body"
            >
              <b-table
                v-if="tableDataSet.length > 0"
                id="endpoint-table"
                class="endpointTable"
                :items="tableDataSet"
                :fields="tableDataFields"
                responsive
              >
                <template #cell(show_details)="row">
                  <a
                    v-if="row.item.endpoint_name"
                    @click="row.toggleDetails"
                  >
                    {{ row.detailsShowing ? '-' : '+' }}
                  </a>
                  <span
                    v-else
                    class="unknown"
                  />
                </template>

                <template #cell(Endpoints)="row">
                  <a
                    v-if="row.item.endpoint_name"
                    @click="row.toggleDetails"
                  >
                    {{ row.item.endpoint_name }}
                  </a>
                  <template
                    v-else
                  >
                    Unknown
                  </template>
                </template>

                <template #cell(Variants)="row">
                  {{ row.item.variants }}
                </template>

                <template #cell(Participants)="row">
                  {{ row.item.participants }}
                </template>

                <template
                  #row-details="row"
                >
                  <div
                    v-if="variants[row.item.endpoint_name]"
                    class="expanded-content"
                  >
                    <b-table
                      :class="expanded-row"
                      :items="variants[row.item.endpoint_name]"
                      :striped="false"
                    />
                  </div>
                </template>
              </b-table>
              <div
                v-else
                class="section-panel-body"
              >
                <div class="no-data">
                  There isn't any endpoint data available yet
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateFormatMixin from '@/mixins/DateFormatMixin';
import OpsDashMixin from '@/mixins/OpsDashMixin';

import SelectDates from './components/SelectDates.vue';
import PieChart from './components/charts/EndpointsPie.js';

export default {
  name: 'EndpointsDrilldown',
  components: {
    PieChart,
    SelectDates,
  },
  mixins: [
    ChartDataMixin,
    DateFormatMixin,
    OpsDashMixin,
  ],
  data() {
    return {
      componentTitle: this.$route.name,
      chartWidth: 100,
      tableDataFields: [
        {key: 'show_details', label: '', tdClass: 'expand-btn'},
        {key: 'Endpoints', label: 'Endpoints', tdClass: 'expand'},
        'Variants',
        'Participants',
      ],
      tableDataSet: [],
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'DD-MM-YYYY',
      },
      attributes: [],
      variants: {},
      polling: null,
      expanded: null,
      isUpdating: false,
    };
  },
  computed: {
    isLoading() {
      return (!this.historicEndpointsCharts.endpoints ? true : false);
    },
    isLoadingData() {
      return (!this.historicEndpointsData.endpoints ? true : false);
    },
    areDatesLoaded() {
      return this.range.start && this.range.end;
    },
    tableData() {
      return this.historicEndpointsData;
    },
    chartData() {
      return this.historicEndpointsCharts;
    },
    variantData() {
      return this.historicEndpointsVariants;
    },
    dateStart() {
      return this.historicEndpointsData.from_date;
    },
    dateEnd() {
      return this.historicEndpointsData.to_date;
    },
    dateRange() {
      return this.range;
    },
    pieChartStyles() {
      return {
        height: '#{this.chartWidth}px',
        width: '#{this.chartWidth}px',
        position: 'relative',
      };
    },
    historicEndpointsCharts() {
      return meetingStore.historicEndpointsCharts;
    },
    historicEndpointsData() {
      return meetingStore.historicEndpointsData;
    },
    historicEndpointsVariants() {
      return meetingStore.historicEndpointsVariants;
    },
  },
  watch: {
    tableData: function() {
      this.isUpdating = false;
      this.tableDataSet = this.historicEndpointsData.endpoints;
      this.range.start = this.formatDateYMD(this.historicEndpointsData.from_date);
      this.range.end = this.formatDateYMD(this.historicEndpointsData.to_date);
      this.getVariants();
    },
    variantData: function() {
      this.variants = this.variantData;
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  // mounted() {
  //   this.chartWidth = this.chartContainerWidth();
  // },
  methods: {
    getData(start, end) {
      if (start || end) {
        this.updateDates(start, end);
      }
      this.isUpdating = true;
      meetingStore.getHistoricEndpointsCharts(this.createDates(this.range.start, this.range.end));
      meetingStore.getHistoricEndpointsData(this.createDates(this.range.start, this.range.end));
    },
    getVariants() {
      const obj = {
        dates: this.createDates(),
        endpoints: this.createVariantsArray(),
      };
      meetingStore.getHistoricEndpointsVariants(obj);
    },
    createVariantsArray() {
      const variantsArray = [];
      this.tableData.endpoints.forEach((item) => {
        const variantObj = {};
        const objName = item.endpoint_name;
        variantObj[objName] = [];
        variantsArray.push(variantObj);
      });
      return variantsArray;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/__variables";

  .container-fluid {
    max-width: $container-width;
  }

  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading,
    .no-data {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: left;
      padding: 2rem;
    }
    .no-data {
      color: $color-gray;
      height: 100%;
      padding: 0;
    }
  }

  .charts-wrapper,
  .table-wrapper {
    margin-top: 1rem;
  }

  .charts-wrapper {
    display: flex;

    > div {
      flex: 1 1 31%;
      margin-top: 0;
      justify-content: space-between;
      overflow: hidden;

      .section-panel-body {
        width: 100%;

        .chart-container {
          width: 100%;
        }
      }
    }
  }

  ::v-deep .table > tbody > tr > td {
     vertical-align: middle;
  }

  .expand-btn {
    width: 0.5rem;
  }

  .table-wrapper {
    #endpoint-table {
      .expanded-content {
        font-size: 0.9em;
        background: $bg-body;
        margin: -0.75rem -0.75rem 1rem;

        ::v-deep table {
          margin-bottom: 0;
        }
      }
    }
  }

  .endpointTable ::v-deep .expand {
    font-size: 1rem;
    width: 70%;
    white-space: nowrap;

    > a {
      color: var(--primary-color);
      font-size: 1.7rem;
      font-weight: bold;
    }
  }
  .endpointTable ::v-deep .expand-btn {
    padding: 0;
    overflow: hidden;
    vertical-align: middle;
    width: 50px;

    > a {
      color: $white;
      background-color: var(--primary-color);
      display: inline-block;
      padding: 25px 0;
      line-height: 0;
      text-align: center;
      width: 100%;

      &:hover {
        background: var(--primary-color-darker);
      }
    }
  }
  .endpointTable ::v-deep .unknown {
    background-color: $multiselect-background-disabled;
    color: $inactive-text-color;
    display: block;
    height: 50px;
    width: 100%;
  }
</style>
