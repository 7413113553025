<template>
  <div
    class="select-dates section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Select Dates</h3>
      </div>
      <div
        class="section-panel-header-action date-range"
      >
        <vc-date-picker
          v-model="range.start"
          :masks="masks"
          color="teal"
          :attributes="attributes"
          :max-date="maxRangeMinus1"
          is-required
        >
          <input
            class="bg-white border px-2 py-1 rounded"
            disabled
          >
          <template #default="{ inputValue, inputEvents }">
            <input
              class="bg-white border px-2 py-1 rounded"
              :value="inputValue"
              v-on="inputEvents"
            >
          </template>
        </vc-date-picker>
        <svg
          class="w-4 h-4 mx-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
        <vc-date-picker
          v-model="range.end"
          :masks="masks"
          color="teal"
          :attributes="attributes"
          :min-date="range.start"
          :max-date="new Date()"
          is-required
        >
          <template #default="{ inputValue, inputEvents }">
            <input
              class="bg-white border px-2 py-1 rounded"
              :value="inputValue"
              v-on="inputEvents"
            >
          </template>
        </vc-date-picker>
        <button
          class="btn btn-secondary"
          :disabled="updating"
          @click="sendDates"
        >
          <span :class="['updating' ,{'show': updating}]">
            Updating&hellip;
          </span>
          <span :class="{'hide': updating}">
            Change Dates
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import DateFormatMixin from '@/mixins/DateFormatMixin';

export default {
  name: 'SelectDates',
  mixins: [
    DateFormatMixin,
  ],
  props: {
    updating: {
      type: Boolean,
      default: false,
    },
    initialRange: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      masks: {
        input: 'DD-MM-YYYY',
      },
      range: {
        start: null,
        end: null,
      },
      attributes: [],
    };
  },
  computed: {
    maxRangeMinus1() {
      return this.range.end ? this.getPreviousDay(this.range.end) : this.range.end;
    },
  },
  mounted() {
    this.range = {...this.initialRange};
  },
  methods: {
    sendDates() {
      this.$emit('get-data', this.range.start, this.range.end);
    },
  },
};
</script>

<style lang="scss" scoped>
  .date-range {
    display: flex;

    svg {
      width: 20px;
    }

    button {
      height: auto;
      margin-left: 20px;
      padding: 0.35rem 0.85rem;
      transition: 0.25s all;

      span {
        display: block;

        &.updating {
          position: absolute;
          visibility: hidden;
        }

        &.show {
          opacity: 0.5;
          visibility: visible;
        }

        &.hide {
          visibility: hidden;
        }
      }
    }
  }
</style>
